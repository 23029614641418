<template>
    <div class="animated fadeIn">
        <LoadingWidget v-if="initStatus != 1" :status="initStatus" @init="init" />
        <b-card v-else>
            <template v-slot:header>
                <h5>Detail Pengiriman</h5>
            </template>
            <b-row align-h="between">
                <b-col sm="4">
                    <img :src="$logoPath" width="200px" />
                </b-col>
                <b-col sm="4">
                    <p>
                        <strong>Invoice : {{ noInvoice }}</strong>
                    </p>
                    <p>Tanggal : {{ tanggal }}</p>
                    <p>Kurir : {{ kurir }}</p>
                    <div v-if="flag == 1">
                        <p v-if="status == 0" class="btn btn-danger text-white">Selisih Barang</p>
                        <p v-if="status == 1" class="btn btn-secondary">Belum Proses</p>
                        <p v-if="status == 2" class="btn btn-info text-white">Dikirim</p>
                        <template>
                            <p v-if="status_approval == 1" class="badge badge-success text-white">Sudah Approve</p>
                            <p v-else class="badge badge-secondary text-white">Belum Approve</p>
                        </template>
                    </div>
                    <div v-if="flag == 0">
                        <p class="btn btn-secondary">Belum Proses</p>
                        <div>
                            <span v-if="status_approval == 1" class="badge badge-success text-white">Sudah Approve</span>
                            <span v-else class="badge badge-secondary text-white">Belum Approve</span>
                        </div>
                    </div>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="6" sm="3">
                    <p><strong>From</strong></p>
                    <p>{{ pengirim }}</p>
                </b-col>
                <b-col cols="6" sm="3">
                    <p><strong>To</strong></p>
                    <p>{{ penerima }}</p>
                    <p>Jl. Arteri Klapa Dua Kav.12A, Kebon Jeruk</p>
                </b-col>
            </b-row>
            <div class="search-wrapper d-flex align-items-center justify-content-between">
                <b-form-group label="Filter" label-for="filterInput" class="form-inline form-group-wrapper mb-3">
                    <div class="d-flex search-input-wrapper">
                        <b-form-input class="ml-2 mr-2" v-model="filter" name="filterTable" id="filterInput" type="search"
                            placeholder="Type to search"></b-form-input>
                        <b-button class="mr-2" variant="pgiBtn" name="searchTableBtn" :disabled="isBusy"
                            @click="onFilter(filter)">
                            <i class="fa fa-search"></i>
                        </b-button>
                        <!-- <b-button variant="pgiBtn" name="resetTableBtn" :disabled="isBusy"
                            @click="onReset()">Reset</b-button> -->
                    </div>
                </b-form-group>
            </div>

            <!-- JIKA FLAG 0 -->
            <b-row v-if="flag == 0">
                <b-col class="d-flex justify-content-xs-center justify-content-sm-end my-2">
                    <b-button variant="pgiBtn" :disabled="total_unit" class="mr-2"
                        @click="$bvModal.show('m-confirm-proses-pengiriman')">
                        <i class="fa fa-check"></i>
                        Proses Pengiriman
                    </b-button>
                    <b-button variant="pgiBtn" class="mr-2" @click="$bvModal.show('m-create')">
                        <i class="fa fa-plus"></i>
                        Tambah Barang
                    </b-button>
                    <b-button variant="pgiBtn" class="mr-2" @click="$bvModal.show('m-create')">
                        <i class="fa fa-upload"></i>
                        Import Bulk
                    </b-button>
                    <b-button variant="pgiBtn-danger" name="detailBarangAddBtn" @click="$bvModal.show('m-confirm-delete')">
                        <i class="fa fa-trash"></i>
                        Hapus Pengiriman
                    </b-button>
                </b-col>
            </b-row>
            <!-- JIKA FLAG 1 -->
            <b-row v-if="flag == 1">
                <b-col class="d-flex justify-content-xs-center justify-content-sm-end my-2">
                    <b-button :disabled="isLoading" @click="handleApprovePengiriman" variant="pgiBtn"
                        v-if="status_approval === 0">
                        <i class="fa fa-check" v-if="!isLoading"></i>
                        <i class="fa fa-spin fa-spinner" v-if="isLoading"></i>
                        Approve Pengiriman
                    </b-button>
                </b-col>
            </b-row>
            <b-table bordered hover show-empty :busy="isBusy" :items="pengiriman" :fields="fields"
                :currentPage="currentPage" :perPage="perPage" @filtered="onFiltered" responsive class="mb-3">
                <template v-slot:table-busy>
                    <div class="text-center text-danger my-2">
                        <b-spinner class="align-middle"></b-spinner>
                        <strong> Loading data...</strong>
                    </div>
                </template>
                <template v-slot:empty>
                    Tidak ada data Pengiriman.
                </template>
                <template v-slot:cell(kelengkapan)="row">
                    <ul class="list-none-wrapper" v-if="row.item.kelengkapan.length > 0">
                        <li v-for="kelengkapan in row.item.kelengkapan" v-bind:key="kelengkapan.id">
                            <span>
                                <i class="fa fa-check" v-if="(kelengkapan.isActive == true)"></i>
                                <i class="fa fa-times" v-if="(kelengkapan.isActive == false)"></i>
                                {{ kelengkapan.name }}
                            </span>
                        </li>
                    </ul>
                    <span v-else>-</span>
                </template>
                <!-- <template v-slot:cell(action)="row">
                    <div class="no-wrap">
                        <div v-if="flag == 1">
                            <div v-if="row.item.status == 0">
                                <b-button size="sm" class="mr-3 table-button edit" v-b-tooltip.hover title="Terima"
                                    name="detailBarangEditBtn">
                                    <i class="fa fa-check" @click="showModalTerimaBarang(`${row.item.id}`)"></i>
                                </b-button>
                                <b-button size="sm" class="mr-3 table-button edit" v-b-tooltip.hover
                                    title="Selisih / tidak ada" name="detailBarangEditBtn">
                                    <i class="fa fa-warning text-danger"
                                        @click="showModalSelisihBarang(`${row.item.id}`)"></i>
                                </b-button>
                            </div>
                        </div>
                        <b-button v-if="flag == 0" size="sm" class="mr-3 table-button edit" v-b-tooltip.hover title="Delete"
                            name="jenisBarangHapusBtn" @click="showModalHapusBarang(`${row.item.id}`)">
                            <i class="fa fa-trash text-danger"></i>
                        </b-button>
                        <b-badge variant="success" v-if="row.item.status == 1">
                            Diterima
                        </b-badge>
                        <b-button size="sm" v-if="row.item.status == 2" class="mr-3 table-button edit" v-b-tooltip.hover
                            title="Kirim ulang" name="detailBarangEditBtn">
                            <i class="fa fa-send" @click="showModalKirimUlang(`${row.item.id}`)"></i>
                        </b-button>
                        <b-badge variant="warning" v-if="row.item.status == 2">
                            Ajukan reclaim
                        </b-badge>
                    </div>
                </template> -->
            </b-table>

            <!-- MODAL CREATE -->
            <b-modal id="m-create" size="lg" title="Formulir Pengiriman" no-close-on-backdrop no-close-on-esc hide-footer>
                <b-row>
                    <b-col sm="8">
                        <b-form-group>
                            {{ form.harga }}
                            <label>Jenis Barang</label>
                            <multiselect v-model="idJenis" :options="jenisBarang" label="nama_barang" track-by="id"
                                class="header-select mr-3" placeholder="Masukan Kode / Nama Barang" :searchable="true"
                                :internal-search="false" :loading="isLoading" :clear-on-select="false"
                                :show-no-results="false" @search-change="searchBarang" />
                        </b-form-group>
                    </b-col>
                    <b-col sm="4">
                        <b-form-group label="Stok Barang">
                            <b-form-input id="stok" v-model="stok" type="text" readonly></b-form-input>
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col sm="6">
                        <b-form-group label="jumlah" :disabled="tipeKode == 1 ? true : false">
                            <b-form-input id="jumlah" v-model="form.jumlah" type="number" min="0"></b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col sm="6">
                        <b-form-group label="Satuan">
                            <b-form-input id="satuan" v-model="form.satuan" type="text" readonly></b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col sm="4" hidden="true">
                        <b-form-group label="Harga" label-for="harga">
                            <b-input-group>
                                <b-input-group-prepend>
                                    <b-input-group-text>Rp.</b-input-group-text>
                                </b-input-group-prepend>
                                <b-form-input v-model="form.harga" id="harga" placeholder="" required=""></b-form-input>
                            </b-input-group>
                        </b-form-group>
                    </b-col>
                    <b-col sm="4" hidden="true">
                        <b-form-group label="Total" label-for="total">
                            <b-input-group>
                                <b-input-group-prepend>
                                    <b-input-group-text>Rp.</b-input-group-text>
                                </b-input-group-prepend>
                                <b-form-input v-model="form.total_harga" id="harga" placeholder="" required=""
                                    readonly></b-form-input>
                            </b-input-group>
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col :hidden="tipeKode == 1 ? false : true">
                        <b-form-group label="Imei">
                            <b-form-input id="imei" v-model="form.imei" type="text"></b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col>
                        <b-form-group label="Detail">
                            <b-form-input id="detail" v-model="form.detail_barang" type="text"></b-form-input>
                        </b-form-group>
                    </b-col>
                </b-row>
                <div class="mt-4 text-right">
                    <b-button variant="pgiBtn" @click="prepare('m-confirm-create')">
                        Simpan
                    </b-button>
                </div>
            </b-modal>

            <ModalConfirm id="m-confirm-create" title="Confirm Tambah Barang" :isSubmitting="isSubmitting"
                desc="Apakah anda yakin untuk barang ini ?" @submit="onSubmit" />

            <ModalConfirm id="m-confirm-delete" title="Confirm Delete" :isSubmitting="isSubmitting"
                desc="Apakah anda yakin untuk menghapus Pembelian ini ?" @submit="hapus" />

            <ModalConfirm id="m-confirm-delete-barang" title="Confirm Delete" :isSubmitting="isSubmitting"
                desc="Apakah anda yakin untuk menghapus Barang ini ?" @submit="hapusBarang" />

            <ModalConfirm id="m-confirm-proses-pengiriman" title="Confirm Proses" :isSubmitting="isSubmitting"
                desc="Apakah anda yakin untuk memproses pengiriman ini ?" @submit="prosesPengiriman" />

            <ModalConfirm id="m-confirm-terima-barang" title="Confirm Terima Barang" :isSubmitting="isSubmitting"
                desc="Pastikan barang dan jumlahnya sudah benar" @submit="terimaBarang" />

            <ModalConfirm id="m-confirm-selisih-barang" title="Confirm Selisih Barang" :isSubmitting="isSubmitting"
                desc="Apakah anda yakin jumlah barang tidak sesuai ?" @submit="selisihBarang" />


            <ModalConfirm id="m-confirm-kirim-ulang" title="Confirm Kirim Ulang" :isSubmitting="isSubmitting"
                desc="Apakah anda yakin untuk kirim ulang ?" @submit="kirimUlang" />




        </b-card>
    </div>
</template>

<script>
import axiosClient from '../../../../config/api';


export default {
    name: 'master-jenis-barang',

    data() {
        return {
            id: 0,
            initStatus: 0,
            isBusy: true,
            input: {
                show: 10
            },
            isLoading: false,
            isSubmitting: false,

            fields: [
                { key: "no", label: "No", sortable: true },
                { key: "namaBarang", label: "Nama Barang", },
                { key: "qty", label: "Qty" },
                { key: "satuan", label: "Satuan" },
                { key: "detail", label: "Detail" },
                { key: "harga", label: "Harga" },
                { key: "totHarga", label: "Total Harga" },
                // { key: "action", label: "Aksi" },
            ],
            filter: null,
            options: [
                { value: 10, text: '10' },
                { value: 20, text: '20' },
                { value: 30, text: '30' },
                { value: 40, text: '40' },
                { value: 50, text: '50' },
            ],

            form: {
                id_tipe: "",
                harga: null,
                jumlah: null,
                satuan: "",
                total_harga: "",
                imei: "",
                detail_barang: "",
                id_pengiriman: "",
                keterangan: "",
                status: 0,
            },

            //jenisBarangAll: [],
            jenisBarang: [],
            merkBarang: [],
            tipeBarang: [],

            activeId: null,
            total_unit: false,
            idJenis: null,
            idMerk: null,
            stok: null,

            noInvoice: null,
            tanggal: null,
            flag: null,
            status: null,
            status_approval: null,
            pengirim: null,
            kurir: null,
            penerima: null,
            pengiriman: [],

            tipeKode: null,

            currentPage: 1,
            perPage: 10,
            rows: null,
            totalViewText: "",
            page: '',
            size: ''
        }
    },
    methods: {

        //----------------- Fungsi Inisialisasi -----------------

        async searchBarang(query) {
            this.jenisBarang = []

            if (query.length > 2) {
                this.isLoading = true;
                // make API request to load websites
                // this.cabang_pengirim
                let request = await this.$axios.get('api/internal-memo/memo/stock-barang-v3?kode_cabang=' + "0999" + '&search=' + query)
                //let data = await request.json()
                if (request.data.data.length != 0) {
                    for (var data of request.data.data) {
                        data.nama_barang = data.nomer_barang + ' - ' + data.barang_tipe.tipe
                    }
                    this.jenisBarang = request.data.data
                }
                this.isLoading = false;
            }
        },

        init() {
            this.initStatus = 0

            this.id = this.$route.params.id
            this.form.id_pengiriman = this.$route.params.id
            let getPengiriman = this.$axios.get("api/transaksi/pengiriman/" + this.id).then(response => {
                let datas = response.data.data
                if (response.data.data.detail.length == 0) {
                    this.total_unit = true
                } else {
                    this.total_unit = false
                }
                this.noInvoice = datas.no_invoice
                this.tanggal = datas.tanggal
                this.flag = datas.flag
                this.status = datas.status
                this.status_approval = datas.status_approval
                this.kurir = datas.kurir

                this.cabang_pengirim = (datas.cabang_pengirim.kode != null) ? datas.cabang_pengirim.kode : datas.cabang_pengirim.kode_cabang


                let penerima = "-"
                let pengirim = "-"

                if (datas.cabang_penerima != null) {
                    penerima = (datas.cabang_penerima.name != null) ? datas.cabang_penerima.name : datas.cabang_penerima.nama_cabang
                }
                if (datas.cabang_pengirim != null) {
                    pengirim = (datas.cabang_pengirim.name != null) ? datas.cabang_pengirim.name : datas.cabang_pengirim.nama_cabang
                }
                this.pengirim = pengirim
                this.penerima = penerima
                if (datas.detail.length != 0) {
                    let i = 1
                    for (let data of datas.detail) {
                        this.pengiriman.push({
                            no: i,
                            id: data.id,
                            id_pengiriman: data.id_pengiriman,
                            status: data.status,
                            namaBarang: data.barang_tipe[0].tipe,
                            qty: data.jumlah,
                            satuan: data.satuan,
                            detail: data.detail_barang,
                            kurir: data.kurir ? data.kurir : "-",
                            harga: 'Rp. ' + data.harga.toString().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,"),
                            totHarga: 'Rp. ' + data.total_harga.toString().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")
                        })
                        i++
                    }
                    //this.jenisBarangAll = response.data
                }

                // console.log(this.jenisBarang);
                this.updateTotalItem()
            })

            Promise.all([getPengiriman]).then(() => {
                this.initStatus = 1
                let msg = this.$helper.getFlash()
                this.$helper.toastSucc(this, msg)
                this.toggleBusy()
            })
                .catch(error => {
                    console.log(error)
                    this.initStatus = -1
                })
        },


        //-----------------  Fungsi Ambil Data Jenis Barang ----------------- 

        getPengiriman() {
            this.toggleBusy()
            this.pengiriman = []
            this.$axios.get("api/transaksi/pengiriman/" + this.id).then(response => {
                let datas = response.data.data
                if (response.data.data.detail.length == 0) {
                    this.total_unit = true
                } else {
                    this.total_unit = false
                }
                this.noInvoice = datas.no_invoice
                this.tanggal = datas.tanggal
                this.flag = datas.flag
                this.status = datas.status

                let penerima = "-"
                let pengirim = "-"

                if (datas.cabang_penerima != null) {
                    penerima = (datas.cabang_penerima.name != null) ? datas.cabang_penerima.name : datas.cabang_penerima.nama_cabang
                }
                if (datas.cabang_pengirim != null) {
                    pengirim = (datas.cabang_pengirim.name != null) ? datas.cabang_pengirim.name : datas.cabang_pengirim.nama_cabang
                }
                this.pengirim = pengirim
                this.penerima = penerima

                if (datas.detail.length != 0) {
                    let i = 1
                    for (let data of datas.detail) {
                        this.pengiriman.push({
                            no: i,
                            id: data.id,
                            id_pengiriman: data.id_pengiriman,
                            status: data.status,
                            namaBarang: data.barang_tipe[0].tipe,
                            qty: data.jumlah,
                            satuan: data.satuan,
                            detail: data.detail_barang,
                            harga: 'Rp. ' + data.harga.toString().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,"),
                            totHarga: 'Rp. ' + data.total_harga.toString().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")
                        })
                        i++
                    }
                    //this.jenisBarangAll = response.data
                }

                // console.log(this.jenisBarang);
                this.updateTotalItem()
                this.toggleBusy()
            })
                .catch(error => {
                    console.log(error)
                    this.initStatus = -1
                })
        },


        //----------------- Fungsi Pagination, Sorting, dan Filtering -----------------

        onPageChange() {
            let prevLimit = null
            let limit = null
            prevLimit = (this.currentPage - 1) * this.input.show + 1
            limit = (this.currentPage - 1) * this.input.show + this.input.show
            if (limit > this.rows) limit = this.rows
            this.totalViewText = "Menampilkan " + prevLimit + " - " + limit + " dari " + this.rows + " entri"
        },

        onLimitChange() {
            this.perPage = this.input.show
            this.onPageChange()
        },

        onFilter(letters) {

            if (this.filter == null) {
                this.$helper.toastErr(this, "Silakan isi nama jenis barang terlebih dahulu!")
            } else {
                let filteredArray = []

                for (var x of this.jenisBarangAll) {
                    if (x.name.toLowerCase().includes(letters.toLowerCase())) {
                        filteredArray.push(x)
                    }
                }

                this.jenisBarang = filteredArray
                this.onFiltered(this.jenisBarang)
            }
        },

        onFiltered(filteredItems) {
            this.rows = filteredItems.length
            this.currentPage = 1
            this.perPage = this.input.show
            let prevLimit = null
            let limit = null

            prevLimit = (this.currentPage - 1) * this.rows + 1
            limit = (this.currentPage - 1) * this.rows + this.rows
            if (limit > this.input.show) limit = this.input.show
            this.totalViewText = "Menampilkan " + prevLimit + " - " + limit + " dari " + this.rows + " entri"
        },

        onReset() {
            this.filter = ""
            this.toggleBusy()
            this.getPengiriman()
        },
        async handleApprovePengiriman() {
            const id_pengiriman = this.$route.params.id;
            try {
                this.isLoading = true
                const response = await axiosClient({
                    method: 'POST',
                    url: `transaksi/pengiriman/approve-pengiriman`,
                    data: {
                        id_pengiriman: id_pengiriman
                    }
                })
                if (response.data.code === 200) {
                    this.$helper.toastSucc(this, "Pengiriman berhasil diapprove")
                    this.toggleBusy()
                    this.init()
                } else {
                    this.$helper.toastErr(this, "Pengiriman gagal diapprove")
                }

            } catch (error) {
                console.log(error)
            } finally {
                this.isLoading = false
            }
        },


        //----------------- Fungsi Pindah Halaman dan Fungsi Lain -----------------

        toggleBusy() {
            if (this.isBusy == true) this.isBusy = false
            else this.isBusy = true
        },

        updateTotalItem() {
            this.rows = this.jenisBarang.length
            if (this.perPage > this.rows) this.perPage = this.rows
            this.totalViewText = "Menampilkan 1 - " + this.perPage + " dari " + this.rows + " entri"
        },

        inputJenisBarang() {
            this.$router.push({ path: `${this.$route.path}/input` });
        },

        prepare(modalToShow) {

            if (this.form.id_tipe == null || this.form.id_tipe == "") {
                return this.$helper.toastErr(this, "Mohon pilih Jenis Barang!")
            }
            if (this.form.jumlah == null || this.form.jumlah == "" || this.form.jumlah == 0) {
                return this.$helper.toastErr(this, "Mohon masukan jumlah barang!")
            }
            if (this.stok == 0) {
                return this.$helper.toastErr(this, "Stok Barang Kosong!")
            }
            if (this.form.jumlah > this.stok) {
                return this.$helper.toastErr(this, "Stok Barang tidak mencukupi!")
            }
            if (this.form.harga == null || this.form.harga == "") {
                return this.$helper.toastErr(this, "Mohon masukan harga barang!")
            }

            if (this.tipeKode == 1) {
                if (this.form.imei == "-" || this.form.imei == '' || this.form.imei == null) {
                    return this.$helper.toastErr(this, "Mohon mengisi Imei!")
                }
            } else {
                this.form.imei = '-'
            }

            this.$bvModal.show(modalToShow)

        },

        onSubmit() {
            let formSubmit = this.form
            formSubmit.harga = this.$helper.removeThousandSeparator(this.form.harga)
            formSubmit.total_harga = this.$helper.removeThousandSeparator(this.form.total_harga)
            this.$axios.post('api/transaksi/pengiriman/detail', formSubmit).then((response) => {

                this.idJenis = null
                this.stok = 0
                this.form = {
                    id_tipe: "",
                    harga: null,
                    jumlah: null,
                    satuan: "",
                    total_harga: "",
                    imei: "",
                    detail_barang: "",
                    id_pengiriman: this.id,
                    keterangan: "",
                    status: 0,
                },

                    this.$bvModal.hide("m-create")
                this.$bvModal.hide("m-confirm-create")
                this.$helper.toastSucc(this, "Barang berhasil ditambahkan!")

                this.getPengiriman()
            })
        },

        //----------------- HAPUS PEMBELIAN -----------------
        hapus() {
            //console.log(this.idPembelian);
            this.$axios.delete("api/transaksi/pengiriman/delete/" + this.id).then(() => {
                this.$helper.setFlash(`Pengiriman berhasil dihapus`)
                this.$router.push("/input-data/pengiriman")

            })
        },

        showModalHapusBarang(id) {
            this.activeId = id
            this.$bvModal.show('m-confirm-delete-barang')
        },

        hapusBarang() {
            this.$axios.delete("api/transaksi/pengiriman/detail/delete/" + this.activeId).then(() => {
                this.$bvModal.hide("m-confirm-delete-barang")
                this.$helper.toastSucc(this, "Barang Berhasil dihapus")

                this.getPengiriman()

            })
        },

        //----------------- Proses pengiriman -----------------

        prosesPengiriman() {
            let form = {
                id: this.id,
                flag: 1,
                status: 2,
            }
            this.$axios.post("api/transaksi/pengiriman/update", form).then(() => {
                this.$bvModal.hide("m-confirm-proses-pengiriman")
                this.$helper.toastSucc(this, "Pengiriman Berhasil diproses")
                this.getPengiriman()

            })
        },

        //----------------- Proses Terima Barang -----------------
        showModalTerimaBarang(id) {
            this.activeId = id
            this.$bvModal.show('m-confirm-terima-barang')
        },

        terimaBarang() {
            let form = {
                id: this.activeId,
                status: 1
            }
            this.$axios.post("api/transaksi/pengiriman/update/detail", form).then(() => {
                this.$bvModal.hide("m-confirm-terima-barang")
                this.$helper.toastSucc(this, "barang berhasil diterima")

                this.getPengiriman()

            })
        },

        //----------------- Proses Selisih Barang -----------------
        showModalSelisihBarang(id) {
            this.activeId = id
            this.$bvModal.show('m-confirm-selisih-barang')
        },

        selisihBarang() {
            let form = {
                id: this.activeId,
                status: 2
            }
            this.$axios.post("api/transaksi/pengiriman/update/detail", form).then(() => {
                this.$bvModal.hide("m-confirm-selisih-barang")
                this.$helper.toastSucc(this, "barang dinyatakan selisih, silahkan ajukan reclaim")

                this.getPengiriman()

            })
        },

        //----------------- Proses Kirim Ulang -----------------
        showModalKirimUlang(id) {
            this.activeId = id
            this.$bvModal.show('m-confirm-kirim-ulang')
        },

        kirimUlang() {
            let form = {
                id: this.activeId,
                status: 0
            }
            this.$axios.post("api/transaksi/pengiriman/update/detail", form).then(() => {
                this.$bvModal.hide("m-confirm-kirim-ulang")
                this.$helper.toastSucc(this, "barang telah dikirim ulang")

                this.getPengiriman()

            })
        },

    },

    watch: {

        'idMerk': function (id) {
            this.tipeBarang = []
            this.form.id_tipe = null
            this.form.satuan = null
            if (id != null) {
                this.$axios.get("api/admin/barang-tipe/all" + "?id_merk=" + id.id).then(response => {
                    let datas = response.data
                    //console.log(datas.data);
                    for (var data of datas.data) {
                        this.tipeBarang.push({
                            id: data.id,
                            tipe: data.tipe,
                            satuan: data.satuan
                        })
                    }

                    if (this.isBusy == true) this.toggleBusy()
                })
                    .catch(error => {
                        console.log(error)
                        this.$helper.parseError(this, error)
                        if (this.isBusy == true) this.toggleBusy()
                    })
            }

        },

        // 'form.id_tipe': function (id) {
        //     if(id != null){
        //         this.form.satuan = id.satuan
        //     }
        // },

        "form.harga": function (newValue) {
            let cleanValue = 0
            if (newValue != 0) {
                cleanValue = this.$helper.changeToValue(newValue);
            }

            let harga = (this.form.jumlah == null) ? 1 : parseInt(this.form.jumlah) * parseInt(cleanValue)

            const result = cleanValue.toString().replace(/(?!^-)[^0-9]/g, "")
                .replace(/\B(?=(\d{3})+(?!\d))/g, ".");
            this.$nextTick(() => this.form.harga = result);

            const total = harga.toString().replace(/(?!^-)[^0-9]/g, "")
                .replace(/\B(?=(\d{3})+(?!\d))/g, ".");
            this.$nextTick(() => this.form.total_harga = total);
        },

        "form.jumlah": function (newValue) {
            let cleanValue = 0
            if (this.form.harga != null) {
                cleanValue = this.$helper.changeToValue(this.form.harga);
            }
            let harga = newValue * parseInt(cleanValue)
            const total = harga.toString().replace(/(?!^-)[^0-9]/g, "")
                .replace(/\B(?=(\d{3})+(?!\d))/g, ".");
            this.$nextTick(() => this.form.total_harga = total);
        },

        "idJenis": function (newVal) {
            if (newVal.jumlah_stok < 0) {
                this.stok = 0
            } else {
                this.stok = newVal.jumlah_stok
            }
            this.form.id_tipe = newVal.id_tipe
            this.form.harga = newVal.barang_tipe.harga.toString()
            this.form.satuan = newVal.satuan
            this.form.imei = newVal.imei
            this.form.detail_barang = newVal.detail_barang
            this.tipeKode = newVal.barang_tipe.tipe_kode
            if (this.tipeKode == 1) {
                this.form.jumlah = 1
            }
        }

    },

    created() {
        this.init()
    }
}
</script>
